.join {
   background-color: $color-neutral;
   padding-bottom: $offset-section;

   &__wrapper {
      display: flex;
      justify-content: end;
      position: relative;

      @include tablet {
         justify-content: end;
         padding-right: 150px;
      }

      @media (max-width: 940px) {
         padding-right: 0px;
      }

      @include mobile {
         justify-content: start;
      }
   }

   &__image {
      position: absolute;
      bottom: 0;
      left: 25%;

      @include tablet {
         left: auto;
         right: 0;
      }

      @include mobile {
         top: -25%;
      }
   }

   &__content {
      padding-bottom: $offset-section;
      padding-top: $offset-secondary;
      color: $color-white;
      max-width: 402px;
      position: relative;
      z-index: 10;

      @include mobile {
         padding-top: 300px;
         padding-left: 30px;
         padding-bottom: 0;
      }
   }

   &__title {
      margin-bottom: $offset-main;
      padding: 0;

      @include mobile {
         max-width: 230px;
      }
   }

   &__subtitle {
      margin-bottom: $offset-main;

      @include mobile {
         max-width: 280px;
      }
   }
}