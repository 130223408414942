$base-font: 'OpenSansRegular',
sans-serif;
$font-secondary :'InterBold',
sans-serif;
$font-secondary-extra-bold: 'InterExtraBold',
sans-serif;
$base-font-size: 16px;
$title-mobile-font-size: 32px;
$title-font-size: 50px;
$base-line-height: 32px;
$title-line-height: 50px;

$title-fz: 62px;
$color-primary: #4AE290;
$color-green-dark: #1ea65d;
$color-neutral: #131316;
$color-gray:rgba(255, 255, 255, 0.9);
$color-white: #fff;
$color-gray-dark:#f3f3f3;

$border-radius: 2px;

$offset-section: 180px;
$offset-main: 32px;
$offset-small: 16px;
$offset-secondary: 80px;