.community {
   margin-bottom: $offset-section;
  
   &__wrapper {
      position: relative;      
      padding-left: 200px;  
      @media (max-width: 1400px) {
         padding-left: 40px;
      }  
      @media (max-width: 991px) {
         padding-left: 30px;
         padding-bottom: 50px;
      }   
      @media (max-width: 660px) {
         padding-left: 18%;
      }
      @media (max-width: 580px) {
         padding-left: 14%;
      }
      @media (max-width: 460px) {
         padding-left: 30px;
         padding-right: 30px;
      }
   }

   &__slider {
      height: 480px;     
      overflow: hidden;   
      padding-right: 0px;      
   }  
   &__swiper {      
      overflow: hidden;
   }

   &__title {
      padding-left: 60px;
      &::before {
         background-image: url('../../img/community-icon.svg');
         background-position: center;
         background-repeat: no-repeat;
         background-size: cover;
         left: 60px;
      }
   }


   &__content {
      position: absolute;
      top: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   &__quote {
      font-style: italic;
      max-width: 320px;
      text-align: center;
      color: $color-white;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: $offset-small;
   }

   &__author {
      position: relative;
      display: inline-block;
      text-align: center;
      font-family: $font-secondary;
      font-size: 20px;
      color: $color-white;
      max-width: 320px;
      display: inline-block;

      &::before {
         position: absolute;
         content: '';
         left: -30px;
         width: 20px;
         height: 20px;
         background-image: url('../../img/quote-line.svg');
         background-position: center;
         background-repeat: no-repeat;
         background-size: contain;
      }
   }

}

.community__button-prev,
.community__button-next {
   width: 36px;
   height: 36px;
   outline: none;
   position: absolute;
   bottom: 20px;
   left: 20px;
   @media (max-width: 1400px) {
      bottom: -60px;
      left: 40px;
   } 
   @media (max-width: 991px) {
      bottom: -20px;
   }
   @media (max-width: 660px) {
      left: 38%;
   
   }
   cursor: pointer;

   &:hover svg {
      fill: $color-primary;
   }

   svg {
      transition: fill 0.3s ease-in-out;
      fill: $color-neutral;
   } 
  
}

.community__button-next {
   margin-left: calc(36px + $offset-main);
}

.swiper-button-disabled {
   svg {
      fill: gray;

      &:hover {
         fill: gray;
      }
   }

}