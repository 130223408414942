.header {
   background-color: transparent;
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   z-index: 10;
   &__wrapper {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      @include tablet {
         flex-direction: column;
         align-items: start;
      }         
   }
   &__logo {
      cursor: pointer;      
   }

   &__list {
      display: flex;
      @include tablet {
         flex-direction: column;
         align-items: center;
      }
   }


   &__item {
      &:not(:last-child) {
         margin-right: $offset-main;
         @media (max-width: 1180px) {
            margin-right: 12px;
         }
         @include tablet {
            margin-bottom: $offset-main;
            margin-right: 0;
         }
      }
   }

   &__link {
      padding: 5px 10px;
      border-radius: $border-radius;
      cursor: pointer;
      color: $color-gray;
      transition: all 0.3s ease-in-out;

      &:hover {
         background-color: $color-gray;
         color: $color-neutral;
      }
   }
   &__buttons {
      align-items: center;
      display: flex;
      @include tablet {         
         width: 100%;
         justify-content: center;
      }
   }
   &__phone {
      color:$color-neutral;
      font-size: 18px;
      font-weight: 700;
      background-color:$color-white;      
      padding:12px 40px;
      border-radius: 5px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      transition: .4s;
      border: 2px solid transparent;
      &:hover {
         background-color:$color-primary;
         transform: translateX(-9px);
         border: 2px solid $color-green-dark;
      }    
      @media (max-width: 1180px) {
         padding: 10px 20px;
      }  
   }
   &__btn {
      font-size: 18px;
      border: 0px solid transparent;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      @media (max-width: 1180px) {
         padding: 10px 20px;
      }
   }

}


.burger {
   z-index: 9999;
   width: 40px;
   height: 22px;
   position: absolute;
   right: 32px;
   top: 32px;
   display: none;
   border: none;
   padding: 0;
   background-color: transparent;
   cursor: pointer;

   @include tablet {
      display: block;
   }

}

.burger__line {
   position: absolute;
   z-index: 9999;
   left: 0;
   top: 50%;
   width: 100%;
   height: 2px;
   background-color: $color-white;
   transform: translateY(-50%);
   transition: opacity 0.3s ease-in-out;
}

.burger::before,
.burger::after {
   content: "";
   position: absolute;
   left: 0;
   width: 100%;
   height: 2px;
   background-color: $color-white;
   transition: transform 0.3s ease-in-out, top 0.3s ease-in-out, bottom 0.3s ease-in-out;
}

.burger::before {
   top: 0;
}

.burger::after {
   bottom: 0;
}

.burger--active .burger__line {
   opacity: 0;
}

.burger--active::before {
   top: 50%;
   transform: rotate(45deg);
}

.burger--active::after {
   bottom: auto;
   top: 50%;
   transform: rotate(-45deg);
}

.stop-scroll {
   overflow: hidden;
}

.header__nav {
   @include tablet {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;     
      padding-top: 160px;
      width: 100%;
      height: 100vh;
      background-color: $color-neutral;
      transform: translateY(-150%);
      transition: 0.6s;

      &+.header__buttons {
         z-index: 1000;
         position: fixed;         
         transform: translateY(-100vh);
         padding-top: 60px;            
         background-color: var(--main-bg);         
         transition:  0.6s;
      }
   }
}

.header__nav--visible {
   @include tablet {
      transform: none;

      &+.header__buttons {
         transform: none;
      }
   }

}