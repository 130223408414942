.modal {   
   width: 100%;
   height: 100vh;
   opacity: 0.95;
   background-color:$color-neutral;
   display: flex;
   justify-content: center;
   align-items: center;
   position: fixed;   
   z-index: 9999;
   opacity: 0;
   visibility: hidden;
   transition: .4s;
   &_visible {
      opacity: 1;
      visibility: visible;
   }
   &__form {
      padding: 40px;
      padding-top: 20px;
      border-radius: 10px;
      background-color:$color-white;
      max-height: 700px;      
      position: relative;
      display: flex;
      flex-direction: column;      
      justify-content: center;
   }
   &__btn-close {
      position: relative;
      margin-left: 100%;      
      width: 20px;
      height: 20px;
      background-color:transparent;
      &::before {        
         position: absolute;
         content: '';
         background-color:$color-neutral;
         width: 20px;         
         height: 2px;
         left: 0;
         top: 0;
         transform: rotate(45deg);
         transition: .3s;
      }
      &::after {
         position: absolute;
         content: '';
         background-color:$color-neutral;
         width: 20px;         
         height: 2px;
         left: 0;
         top: 0;
         transform: rotate(-45deg);
         transition: .3s;
      }
      &:hover {
         &::before {
            background-color:$color-primary;
         }
         &::after {
            background-color:$color-primary;
         }
      }
   }
   &__title {
      font-size: 30px;
      color:$color-neutral;
      font-weight: 700;
      margin-bottom: 30px;
   }
   &__subtitle {
      margin-bottom: 24px;
   }
   &__label {
      margin-bottom: 28px;
      display: block;
   }
   &__input {
      width: 100%;
      border: 2px solid $color-primary;
      border-radius: 5px;
      padding: 10px;
     
   }
   &__btn {
      text-transform: uppercase;
      background-color:$color-primary;
      padding: 20px 40px;
      border-radius: 5px;
      font-size: 18px;       
      font-weight: 700;
      color:$color-white;   
   }
   &__text {
      margin-bottom: 6px;
   }
}