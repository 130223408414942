html {
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "OpenSansRegular", sans-serif;
  overflow-x: hidden;   
  height: 100%;
  position: relative;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.stop-scroll {  
  overflow: hidden;
}

.container {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.btn_reset {
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: "InterBold";
  font-size: 14px;
}

.btn {
  background-color: $color-primary;
  font-size: 14px;
  color: $color-white;
  text-transform: uppercase;
  padding: 14px 40px;
  border-radius: $border-radius;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: $color-green-dark;
  }

  &_fill {
    text-transform: uppercase;
    font-family: "InterBold";
    font-weight: 700;
    padding: 14px 40px;
    color: $color-neutral;
    background-color: $color-white;
    border-radius: $border-radius;
    border: 2px solid transparent;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: $color-primary;
    }
  }

  &_stroke {
    text-transform: uppercase;
    font-family: "InterBold";
    font-weight: 700;
    padding: 14px 40px;
    color: $color-neutral;
    background-color: transparent;
    border-radius: $border-radius;
    border: 2px solid $color-neutral;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $color-white;
      background-color: $color-neutral;
    }
  }
}

.btn-secondary {
  text-transform: uppercase;
  padding: 14px 40px;
  color: $color-white;
  background-color: transparent;
  transition: all 0.3s ease-in-out;

  border-radius: $border-radius;

  &:hover {
    color: $color-neutral;
    background-color: $color-white;
  }
}

.title {
  font-size: $title-font-size;
  line-height: $title-line-height;
  font-family: $font-secondary-extra-bold;
  padding-left: 70px;
  padding-top: 35px;
  padding-bottom: 35px;
  position: relative;

  &_green {
    color: $color-primary;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 150px;
    height: 120px;
  }

  @include mobile {
    font-size: $title-mobile-font-size;
  }
}

.d-flex-column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}